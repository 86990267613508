<template>
    <Effects :item="record" />
</template>


<script>
import Effects from '../components/Effects.vue'

export default {
    props: {
        record: {
            type: Object,
            required: true
        },
    },
    components: {
        Effects
    }
}
</script>